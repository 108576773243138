<template>
  <BaseModal :title="unsuccessfulData.title" @on-close-modal="onCloseModal">
    <div class="unsuccessful-modal">
      <div class="unsuccessful-modal__body-img-w">
        <img src="/img/unsuccessful-form.svg" alt="unsuccessful form" />
      </div>
      <p class="unsuccessful-modal__body-text">
        {{ unsuccessfulData.description }}
      </p>
    </div>
  </BaseModal>
</template>

<script setup>
import {
  unsuccessfulModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";
import BaseModal from "~/modules/shared/modals/BaseModal.vue";

const modalStore = useModalStore();
const unsuccessfulData =
  storeToRefs(modalStore).getExtra.value(unsuccessfulModal);

const { start, stop } = useTimeoutFn(() => {
  modalStore.toggleModal(unsuccessfulData);
}, 5000);

onMounted(() => {
  if (unsuccessfulData.isWithTimeout) {
    start();
  }
});

const onCloseModal = () => {
  stop();
  modalStore.toggleModal(unsuccessfulModal);
};
</script>

<style lang="scss" scoped>
.unsuccessful-modal {
  @include flex-container(column, center, center);
  gap: 24px;

  padding: 24px;

  &__body-img-w {
    width: 84px;
    height: 84px;

    @include flex-container(row, center, center);

    border: 2px solid var(--color-red-error-500);
    border-radius: 50%;
  }

  &__body-text {
    @include font(18, 24, 500);
    text-align: center;
  }
}
</style>
